import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 6.1 9.4" {...props}>
      <path
        fill="#fff"
        d="M6.1 4.7L1.4 9.4 0 8l3.3-3.3L0 1.4 1.4 0l4.7 4.7z"
      />
    </svg>
  )
}

export default SvgComponent
