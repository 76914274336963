import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19.926}
      height={14.57}
      viewBox="0 0 19.926 14.57"
      {...props}
    >
      <path d="M19.325 0H.596a.6.6 0 00-.6.6v1.181l9.963 6.676 9.967-6.676V.602A.6.6 0 0019.325 0z" fill="#fff" />
      <path d="M-.004 4.25v7.916a2.4 2.4 0 002.4 2.4h15.12a2.4 2.4 0 002.4-2.4V4.249l-9.963 6.677z" fill="#fff" />
    </svg>
  )
}

export default SvgComponent
