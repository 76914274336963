import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={14} height={12.143} viewBox="0 0 14 12.143" {...props}>
      <path fill="#fff" d="M4 0h10v8H4z" />
      <path d="M2 10.142v-8H0v10h12v-2z" fill="#fff" />
    </svg>
  )
}

export default SvgComponent
