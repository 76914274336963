import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={9.201}
      height={12}
      viewBox="0 0 9.201 12"
      {...props}
    >
      <path
        d="M.823.509c-.825.535-.987 2.206-.675 3.569a12.306 12.306 0 001.626 3.823 12.779 12.779 0 002.828 3.045c1.117.84 2.709 1.374 3.534.839a4.176 4.176 0 001.065-1.142l-.443-.682L7.54 8.083c-.091-.14-.658-.012-.947.151a2.746 2.746 0 00-.747.812c-.267.154-.49 0-.957-.214a5.684 5.684 0 01-1.732-1.831 5.684 5.684 0 01-.962-2.326c-.006-.514-.055-.78.195-.961a2.746 2.746 0 001.046-.351c.267-.2.615-.663.524-.8L2.742.685 2.299.001A4.176 4.176 0 00.823.509z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent
