import React from "react";
import Slider from "react-slick";
 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 
const SimpleSlider = ({children}) =>  {
    // const slideCount = 5;
    const settings = {
      dots: false,
      arrows: false,
      fade: true,
      infinite: true,
      speed: 1200,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover:false,
      slidesToShow: 1,
      slidesToScroll: 1,
      // initialSlide: Math.floor( Math.random() * slideCount )
    };
    return (
      <Slider {...settings}>{children}</Slider>
    );
  }
 
  export default SimpleSlider;

 