/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
//googleFonts
import "typeface-montserrat";
import "./layout.css"
import "./css/style.scss"
import Footer from "./footer"

const Layout = ({ children,type }) => {
  return (
    <>
      <main className={type}>
      <noscript>
        <dl id="browserMsg">
          <dt>JavaScriptの設定を有効にしてください。</dt>
          <dd>お使いのブラウザはJavaScriptをサポートしていないか、JavaScriptの設定が無効になっています。<br/>
          このサイトでは、JavaScriptを使用しています。すべての機能をご利用希望の方は、JavaScriptを有効にしてください。</dd>
        </dl>
      </noscript>
      {children}
      </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
}
Layout.defaultProps = {
  type: ``,
}
export default Layout
