/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image }) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaOGPImage = `${site.siteMetadata.siteUrl}${image || site.siteMetadata.image}`
  const metaOGPURL = `${site.siteMetadata.siteUrl}${pathname}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      // link={[
      //   {rel:"apple-touch-icon", href:"icons/icon-48x48.png",sizes:"48x48"},
      //   {rel:"apple-touch-icon", href:"icons/icon-72x72.png",sizes:"72x72"},
      //   {rel:"apple-touch-icon", href:"icons/icon-96x96.png",sizes:"96x96"},
      //   {rel:"apple-touch-icon", href:"icons/icon-144x144.png",sizes:"144x144"},
      //   {rel:"apple-touch-icon", href:"icons/icon-192x192.png",sizes:"192x192"},
      //   {rel:"apple-touch-icon", href:"icons/icon-256x256.png",sizes:"256x256"},
      //   {rel:"apple-touch-icon", href:"icons/icon-384x384.png",sizes:"384x384"},
      //   {rel:"apple-touch-icon", href:"icons/icon-512x512.png",sizes:"512x512"},
      // ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaOGPImage,
        },
        {
          property: `og:url`,
          content: metaOGPURL,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
          // content: `summary`,
        },
        // {
        //   name: `twitter:creator`,
        //   // content: site.siteMetadata.author,
        //   content: ``,
        // },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `og:image:width`,
          content: 2400,
        },
        {
          name: `og:image:height`,
          content: 1260,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
  image: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
