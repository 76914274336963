import React from "react"
import IcoArr from "./icons/arr"
import IcoWin from "./icons/win"
import IcoMail from "./icons/mail"
import IcoTel from "./icons/tel"
// import styled from "styled-components"
// import "./_mixin.scss"

export default class Button extends React.Component {
  render() {
    let icon;
    
    switch (this.props.icon) {
      case "blank":
        icon = <IcoWin className="icoWin posR"/>;
        break;
      case "mail":
        icon = <IcoMail className="icoMail icoType"/>;
        break;
      case "tel":
        icon = <IcoTel className="icoTel icoType"/>;
        break;
      default:
        icon = <IcoArr className="icoArr"/>;
        break;
    }
    if(this.props.icon === "tel" || this.props.icon === "mail" ){
      return (
        <button className={(this.props.genre === "secondary")?`cBtn1 secondary`:`cBtn1`} type="button">
          {icon}
          {this.props.children}
        </button>
        )
    }
    return (
    <button className={(this.props.genre === "secondary")?`cBtn1 secondary`:`cBtn1`} type="button">
      {this.props.children}
      {icon}
    </button>
    )
  }
}

