/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const Footer = ({ type }) => {
  const { site } = useStaticQuery(
    graphql` query { site { siteMetadata { title } } } `
  )

  return (
    <>
        <footer id="footer">
        <div className="cInnerArea">
          <dl className="aboutThisSite">
            <dt className="text">このサイトについて</dt>
            <dd>
              <ul className="note">
                <li>※ サイト内の金額はすべて税込です</li>
                <li>※ 使用画像はイメージ画像ですので実際とは異なる場合があります</li>
                <li>※ 掲載内容やサービス内容は今後の税制改正や社会情勢などを考慮し随時変更する可能性があります</li>
              </ul>
            </dd>
          </dl>
        </div>
          <p id="copyright" className="textSmall">
            &copy; {new Date().getFullYear()} {`${site.siteMetadata.title}`} All rights reserved.
          </p>
      </footer>
    </>
  )
}

Footer.propTypes = {
  type: PropTypes.string,
}
Footer.defaultProps = {
  type: ``,
}
export default Footer
